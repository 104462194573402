<template>
  <div class="text-blue-900 gap-4 flex flex-col items-center shadow-md py-10 px-6 bg-white rounded-lg">
    <div>
      <img class="rounded-full h-28 w-28 object-cover border-1 border-gray-300" style="width: 7rem; height: 7rem;" :src="photo" :alt="name + ' photo'">
    </div>
    <div class="flex flex-col gap-4 h-full justify-between items-center">
      <div>
        <div class="text-2xl font-semibold text-center">{{ name }}</div>
        <div class="text-lg text-center">{{ title }}</div>
      </div>

      <div>
        <a class="mt-2 flex justify-center" :href="linkedIn" target="_blank">
          <img class="h-7 w-7" src="https://img.icons8.com/ios-glyphs/30/1e3a8a/linkedin.png"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    company: String,
    title: String,
    linkedIn: String,
    photo: String
  }
}
</script>