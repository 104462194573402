<template>
  <div class="bg-hp-gray" style="min-height: 100vh;">
      <div class="w-full py-5">
        <div class="flex gap-4 items-center justify-between max-w-5xl lg:max-w-7xl 2xl:max-w-8xl mx-auto px-4">
          <router-link to="/">
            <img class="mx-auto" src="@/assets/logos/Homeplan logo (new modern April 2022 - NO .com).svg" style="height: 2rem;" alt="Homeplan logo">
          </router-link>
        </div>
      </div>



    <div v-if="!state.authenticated" class="flex flex-col gap-12 items-center justify-center max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4" style="min-height: 90vh;">
      <div>
        <div class="text-2xl font-bold text-blue-900 mb-2 text-center">This page is password protected</div>
        <div class="text-xl font-medium text-blue-900 text-center">Please enter your details and the correct password to continue</div>
      </div>

      <form @submit.prevent="authenticationFormSubmit" class="flex flex-col items-center justify-center gap-4 w-full max-w-xl">
        <div class="grid grid-cols-2 gap-4 w-full">
          <input required v-model="authenticationForm.first_name" type="text" id="fname" placeholder="First name" class="text-center text-xl focus:outline-none w-full px-3 py-2 dark:bg-gray-700 shadow-sm sm:text-base border-1 border-gray-200 dark:border-gray-600 dark:text-white rounded-md" />
          <input required v-model="authenticationForm.last_name" type="text" id="lname" placeholder="Last name" class="text-center text-lg focus:outline-none w-full px-3 py-2 dark:bg-gray-700 shadow-sm sm:text-base border-1 border-gray-200 dark:border-gray-600 dark:text-white rounded-md" />
          <input required v-model="authenticationForm.fund" type="text" id="pass" placeholder="Fund" class="col-span-2 text-center text-lg focus:outline-none w-full px-3 py-2 dark:bg-gray-700 shadow-sm sm:text-base border-1 border-gray-200 dark:border-gray-600 dark:text-white rounded-md" />
          <input required v-model="authenticationForm.password" type="password" id="pass" placeholder="Password" class="col-span-2 text-center text-lg focus:outline-none w-full px-3 py-2 dark:bg-gray-700 shadow-sm sm:text-base border-1 border-gray-200 dark:border-gray-600 dark:text-white rounded-md" />
        </div>

        <div>
          <button type="submit" class="py-2 px-4 text-hp-white bg-blue-800 hover:bg-blue-900 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
            Submit form
          </button>
        </div>
      </form>
    </div>



    <!-- Main content -->
    <div v-else class="pt-24 pb-36 max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4" style="min-height: 100vh;">
      <div class="text-center text-blue-900 font-bold text-4xl mb-16">
        Investor Portal
      </div>

      <div class="flex gap-4 justify-center mb-24">
        <button @click="scheduleCallPressed" class="py-3 px-4 flex items-center gap-4 bg-blue-900 hover:bg-blue-900 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
          Schedule founder call
          <img class="h-6" src="https://img.icons8.com/material-rounded/48/ffffff/right.png"/>
        </button>

        <button @click="state.showingInvestmentModal = true;" class="py-3 px-4 flex items-center gap-4 bg-blue-900 hover:bg-blue-900 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
          Make investment
          <img class="h-6" src="https://img.icons8.com/material-rounded/48/ffffff/right.png"/>
        </button>
      </div>

      <div class="flex flex-col gap-40">
        <!-- Videos -->
        <!-- <div class="mb-12 flex max-w-xl w-full mx-auto justify-center gap-6">
          <div class="w-full">
            <div class="mb-6 font-bold text-2xl text-center text-blue-900">
              Pre-Seed Pitch w/ Demo
            </div> 

            <vue-plyr>
              <div class="plyr__video-embed" style="border-radius: 5px;">
                <iframe
                  src="https://www.youtube.com/embed/8cexm_EILR4"
                  allowfullscreen
                  allowtransparency
                  allow="autoplay"
                />
              </div>
            </vue-plyr>
          </div>
        </div> -->

        <div class="mb-12 flex max-w-4xl w-full mx-auto justify-center gap-6">
          <div class="w-full">
            <div class="grid md:grid-cols-2 gap-6">
              <div>
                <iframe 
                  style="width: 100%; height: 280px; border-radius: 5px;"
                  src="https://www.youtube.com/embed/wQuZeGoleic?si=JmX1oEKrIEwTP4yP" 
                  title="YouTube video player" 
                  frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  allowfullscreen>
                </iframe>

                <div class="text-center text-blue-900 mt-4 font-medium text-xl">
                  Homeplan.com App
                </div>
              </div>

              <div>
                <iframe 
                  style="width: 100%; height: 280px; border-radius: 5px;"
                  src="https://www.youtube.com/embed/8cexm_EILR4" 
                  title="YouTube video player" 
                  frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  allowfullscreen>
                </iframe>

                <div class="text-center text-blue-900 mt-4 font-medium text-xl">
                  Home Care App
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-12 flex max-w-4xl w-full mx-auto justify-center gap-6">
          <div class="w-full">
            <div class="mb-6 font-bold text-2xl text-center text-blue-900">
              Homeplan Roadmap
            </div> 

            <div class="grid grid-cols-2 gap-6">
              <div>
                <iframe 
                  style="width: 100%; height: 280px; border-radius: 5px;"
                  src="https://www.youtube.com/embed/1U0juvibPzo" 
                  title="YouTube video player" 
                  frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  allowfullscreen>
                </iframe>

                <div class="text-center text-blue-900 mt-4 font-medium text-xl">
                  Homeplan.com Walkthrough
                </div>
              </div>

              <div>
                <iframe 
                  style="width: 100%; height: 280px; border-radius: 5px;"
                  src="https://www.youtube.com/embed/ypJ86kD8iZA" 
                  title="YouTube video player" 
                  frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  allowfullscreen>
                </iframe>

                <div class="text-center text-blue-900 mt-4 font-medium text-xl">
                  Home Care Chrome Extension Walkthrough
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- last video we did for homeplan.com / chrome extension -->

        <!-- Documents -->
        <div>
          <div class="text-lg sm:text-2xl text-center font-bold text-blue-900 mb-8">
            Documents
          </div>
          
          <div class="flex justify-center flex-wrap items-start gap-x-8 md:gap-x-16 gap-y-8">
            <div @click="viewFilePressed('incorporation')" class="cursor-pointer flex flex-col gap-4 items-center justify-center">
              <img class="h-20 md:h-40" src="@/assets/file-black.png" alt="File icon">
              <div class="text-lg font-medium">Incorporation</div>
            </div>

            <div @click="viewFilePressed('bylaws')" class="cursor-pointer flex flex-col gap-4 items-center justify-center">
              <img class="h-20 md:h-40" src="@/assets/file-black.png" alt="File icon">
              <div class="text-lg font-medium">By-Laws</div>
            </div>

            <div @click="viewFilePressed('foreign_qualification')" class="cursor-pointer flex flex-col gap-4 items-center justify-center">
              <img class="h-20 md:h-40" src="@/assets/file-black.png" alt="File icon">
              <div class="text-lg font-medium">Foreign Qualification</div>
            </div>

            <div @click="viewFilePressed('ein')" class="cursor-pointer flex flex-col gap-4 items-center justify-center">
              <img class="h-20 md:h-40" src="@/assets/file-black.png" alt="File icon">
              <div class="text-lg font-medium">EIN</div>
            </div>

            <div @click="viewFilePressed('pitch_deck')" class="cursor-pointer flex flex-col gap-4 items-center justify-center">
              <img class="h-20 md:h-40" src="@/assets/file-black.png" alt="File icon">
              <div class="text-lg text-center font-medium">Pitch Deck <br/>(Pre-Seed September '23)</div>
            </div>

            <div @click="viewFilePressed('financial_model')" class="cursor-pointer flex flex-col gap-4 items-center justify-center">
              <img class="h-20 md:h-40" src="@/assets/file-black.png" alt="File icon">
              <div class="text-lg font-medium">Financial Model</div>
            </div>

            <div @click="viewFilePressed('cap_table')" class="cursor-pointer flex flex-col gap-4 items-center justify-center">
              <img class="h-20 md:h-40" src="@/assets/file-black.png" alt="File icon">
              <div class="text-lg font-medium">Cap Table</div>
            </div>

            <div @click="viewFilePressed('mo_annual_report')" class="cursor-pointer flex flex-col gap-4 items-center justify-center">
              <img class="h-20 md:h-40" src="@/assets/file-black.png" alt="File icon">
              <div class="text-lg font-medium">MO Annual Report</div>
            </div>

            <div @click="viewFilePressed('de_annual_report')" class="cursor-pointer flex flex-col gap-4 items-center justify-center">
              <img class="h-20 md:h-40" src="@/assets/file-black.png" alt="File icon">
              <div class="text-lg font-medium">DE Annual Report</div>
            </div>

            <div @click="viewFilePressed('tax_filing')" class="cursor-pointer flex flex-col gap-4 items-center justify-center">
              <img class="h-20 md:h-40" src="@/assets/file-black.png" alt="File icon">
              <div class="text-lg font-medium">Tax Filings</div>
            </div>


            <div @click="viewFilePressed('profit_and_loss')" class="cursor-pointer flex flex-col gap-4 items-center justify-center">
              <img class="h-20 md:h-40" src="@/assets/file-black.png" alt="File icon">
              <div class="text-lg font-medium">Profit & Loss</div>
            </div>

            <div @click="viewFilePressed('stripe_screenshots')" class="cursor-pointer flex flex-col gap-4 items-center justify-center">
              <img class="h-20 md:h-40" src="@/assets/file-black.png" alt="File icon">
              <div class="text-lg w-48 text-center font-medium">Homeowner Dashboard Revenue (Paused)</div>
            </div>

            <div class="flex flex-col gap-4 items-center justify-center">
              <img class="h-20 md:h-40" src="@/assets/file-black.png" alt="File icon">
              <div class="text-lg font-medium w-56 text-center">Stock Purchase Agreement</div>
              <div class="-mt-1 flex gap-5">
                <button class="hover:underline text-lg text-gray-600" @click="viewFilePressed('spa_bash')">
                  Bash
                </button>

                <button class="hover:underline text-lg text-gray-600" @click="viewFilePressed('spa_alex')">
                  Alex
                </button>
              </div>
            </div>


            <div class="flex flex-col gap-4 items-center justify-center">
              <img class="h-20 md:h-40" src="@/assets/file-black.png" alt="File icon">
              <div class="text-lg font-medium w-56 text-center">Technology Assignment Agreement</div>
              <div class="-mt-1 flex gap-5">
                <button class="hover:underline text-lg text-gray-600" @click="viewFilePressed('taa_bash')">
                  Bash
                </button>

                <button class="hover:underline text-lg text-gray-600" @click="viewFilePressed('taa_alex')">
                  Alex
                </button>
              </div>
            </div>
          </div>
        </div>


        <!-- Company -->
        <div>
          <div class="text-lg sm:text-2xl text-center font-bold text-blue-900 mb-8">
            Company
          </div>

          <div class="grid grid-cols-2 md:grid-cols-4 gap-6">
            <div class="bg-blue-100 text-blue-900 rounded-lg p-4">
              <div class="font-medium mb-1">Name</div>
              <div class="text-xl font-bold">Homeplan.com, Inc</div>
            </div>

            <div class="bg-blue-100 text-blue-900 rounded-lg p-4">
              <div class="font-medium mb-1">Location</div>
              <div class="text-xl font-bold">St. Louis, MO</div>
            </div>

            <div class="bg-blue-100 text-blue-900 rounded-lg p-4">
              <div class="font-medium mb-1">Date of Incorporation</div>
              <div class="text-xl font-bold">June 10, 2021</div>
            </div>

            <div class="bg-blue-100 text-blue-900 rounded-lg p-4">
              <div class="font-medium mb-1">Incorp. State / Entity Type</div>
              <div class="text-xl font-bold">Delaware Public Benefit Corp</div>
            </div>
          </div>
        </div>


        <!-- Advisors -->
        <div>
          <div class="text-lg sm:text-2xl text-center font-bold text-blue-900 mb-8">
            Advisors
          </div>

          <div class="grid sm:grid-cols-2 gap-6 max-w-3xl mx-auto">
            <Advisor
              name="Zach Greene"
              company="Quarter Coast"
              title="Partnerships"
              linkedIn="https://www.linkedin.com/in/zach-greene-40b08b19/"
              photo="https://ca.slack-edge.com/TTAGMKMD4-UTA6ETYBH-fa4e5be6b53c-192"
            />

            <Advisor
              name="Amari Smothers"
              company="LinkedIn"
              title="A.I. Technical Advisor"
              linkedIn="https://www.linkedin.com/in/amari-smothers/"
              photo="https://homeplan-assets.s3.us-east-2.amazonaws.com/amari-smothers.jpeg"
            />
          </div>
        </div>


        <!-- Current investors -->
        <div>
          <div class="text-lg sm:text-2xl text-center font-bold text-blue-900 mb-8">
            Current Investors
          </div>

          <table class="w-full bg-white rounded-lg">
            <tr class="bg-hp-white border-1 border-gray-200 rounded-lg text-blue-900">
              <th class="text-left px-6 py-4">Investor</th>
              <th class="text-left px-6 py-4">Investment date</th>
              <th class="text-left px-6 py-4">Investment amount</th>
              <th class="text-left px-6 py-4">Instrument</th>
            </tr>

            <tr v-for="(investor, index) in investors" :key="index" class="transition ease-in-out border-1 border-gray-200 bg-hp-white ">
              <td class="px-6 py-4 flex gap-4 items-center">
                <img class="rounded-full h-8 w-8 object-cover border-1 border-gray-300" :src="investor.photo" :alt="`${investor.name} photo`">
                <span class="font-medium">{{ investor.name }}</span>
              </td>
              <td class="px-6 py-4">{{ investor.date }}</td>
              <td class="px-6 py-4">${{ investor.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</td>
              <td class="px-6 py-4">{{ investor.instrument }}</td>
            </tr>
          </table>
        </div>



        <!-- Equity -->
        <div>
          <div class="text-lg sm:text-2xl text-center font-bold text-blue-900 mb-8">
            Equity
          </div>

          <table class="w-full bg-white rounded-lg">
            <tr class="bg-hp-white border-1 border-gray-200 rounded-lg text-blue-900">
              <th class="px-6 py-4">Name</th>
              <th class="px-6 py-4">Ownership percentage (Common)</th>
            </tr>

            <tr class="transition ease-in-out border-1 border-gray-200 bg-hp-white">
              <td class="px-6 py-4 font-medium text-center">Bash Jones</td>
              <td class="px-6 py-4 text-center">51%</td>
            </tr>

            <tr class="transition ease-in-out border-1 border-gray-200 bg-hp-white">
              <td class="px-6 py-4 font-medium text-center">Alex Goodison</td>
              <td class="px-6 py-4 text-center">49%</td>
            </tr>
          </table>
        </div>


        


        <!-- Fundraising -->
        <div>
          <div class="text-lg sm:text-2xl text-center font-bold text-blue-900 mb-12">
            Fundraising
          </div>

          <div class="w-full bg-gray-200 h-5 rounded-full mt-16 mb-4">
            <div class="bg-blue-900 h-5 rounded-full" style="width: 100%" />
          </div>

          <div class="flex justify-center text-blue-900">
            <div>
              <div class="text-lg font-bold text-center">Launch capital raised</div>
              <div class="text-lg text-center">$31,500</div>
            </div>
          </div>
        </div>


        <div>
          <div class="text-lg sm:text-2xl text-center font-bold text-blue-900 mb-8">
            Founders
          </div>

          <div class="grid sm:grid-cols-2 gap-6">
            <div class="bg-white shadow-md rounded-lg p-6">
              <div class="flex gap-4 items-center mb-6">
                <img class="w-14 h-14 object-cover rounded-full border-1 border-gray-200" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/team-press-image.jpeg" alt="Bash Jones photo">
                <div class="text-blue-900">
                  <div class="text-xl font-bold">Bash Jones</div>
                  <div class="text-xl">Co-founder & CEO</div>
                </div>
              </div>

              <div class="text-blue-900 text-lg mb-8">
                Frontend Engineer, UI/UX Designer, 2x Founder, Homeowner, Home Renovator, Son of Architect Drafter
              </div>

              <div class="flex items-center gap-4">
                <a href="https://twitter.com/imbashjones" target="_blank">
                  <img class="h-6 w-6" src="https://img.icons8.com/ios-filled/50/1e3a8a/twitter.png"/>
                </a>

                <a href="https://www.linkedin.com/in/bash-jones-bay/" target="_blank">
                  <img class="h-7 w-7" src="https://img.icons8.com/ios-glyphs/30/1e3a8a/linkedin.png"/>
                </a>

                <a href="https://bashjones.com/" target="_blank">
                  <img class="h-6 w-6" src="https://img.icons8.com/ios-filled/50/1e3a8a/link--v1.png"/>
                </a>
              </div>
            </div>

            <div class="bg-white shadow-md rounded-lg p-6">
              <div class="flex gap-4 items-center mb-6">
                <img class="w-14 h-14 object-cover rounded-full border-1 border-gray-200" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/people/alex+g.jpeg" alt="Alex Goodison photo">
                <div class="text-blue-900">
                  <div class="text-xl font-bold">Alex Goodison</div>
                  <div class="text-xl">Co-founder & CTO</div>
                </div>
              </div>

              <div class="text-blue-900 text-lg mb-6">
                Full-Stack Engineer, UI Designer, #1 on Product Hunt, BT Young Scientist Category Award, UCC Quercus Entrepreneurship Scholar, Son of Engineer for Mortgage Co.
              </div>

              <div class="flex items-center gap-4">
                <a href="https://twitter.com/alex_goodison" target="_blank">
                  <img class="h-6 w-6" src="https://img.icons8.com/ios-filled/50/1e3a8a/twitter.png"/>
                </a>

                <a href="https://www.linkedin.com/in/alex-goodison-8446b4120/" target="_blank">
                  <img class="h-7 w-7" src="https://img.icons8.com/ios-glyphs/30/1e3a8a/linkedin.png"/>
                </a>

                <a href="https://alexgoodison.com/" target="_blank">
                  <img class="h-6 w-6" src="https://img.icons8.com/ios-filled/50/1e3a8a/link--v1.png"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>

    


    
    <!-- Footer -->
    <div class="flex gap-x-4 gap-y-16 flex-wrap w-full justify-between max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4 pb-28 text-blue-900">
      <div class="w-full flex flex-col gap-4 md:w-80">
        <div>
          <img src="@/assets/logos/Homeplan logo (new modern April 2022 - NO .com).svg" style="height: 1.5rem;" alt="Homeplan logo">
        </div>
        
        <div class="text-center md:text-left font-semibold text-sm text-blue-900">© 2021-2023 Homeplan.com, Inc</div>
      </div>
    </div>



    <InputModal v-show="state.showingInvestmentModal" @close="state.showingInvestmentModal = false">
      <template v-slot:header>Make investment</template>
      <template v-slot:body>
        <div class="flex flex-col mb-2 gap-4">
          <div>
            <label for="amount" class="text-sm font-medium text-gray-700 dark:text-white">Amount you'd like to invest ($)</label>
            <input v-model="investmentForm.amount" type="text" id="amount" placeholder="Enter investment amount..." class="mt-1 focus:outline-none w-full px-3 py-2 dark:bg-gray-700 shadow-sm text-sm sm:text-base border-1 border-gray-200 dark:border-gray-600 dark:text-white rounded-md" />
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <button type="button" @click="contactTeamPressed" class="py-2 px-4 text-hp-white bg-blue-800 hover:bg-blue-900 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
          Contact Team
        </button>
      </template>
    </InputModal>
  </div>
</template>

<script>
import InputModal from "@/components/modals/input-modal.vue";
import Advisor from "@/components/advisor.vue";

const axios = require("axios");

export default {
  components: {
    InputModal,
    Advisor
  },
  data() {
    return {
      state: {
        showingInvestmentModal: false,
        authenticated: false
      },

      investmentForm: {
        amount: null
      },

      investors: [
        { name: 'Lauren Self', date: 'May 8, 2022', amount: 1000, instrument: '$6M SAFE (post money)', photo: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png' },
        { name: 'Kenny Tucker', date: 'March 1, 2022', amount: 10000, instrument: '$5M SAFE (post money)', photo: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png' },
        { name: 'Cecilia Perez', date: 'October 21, 2021', amount: 1000, instrument: '$4M SAFE (post money)', photo: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png' },
        { name: 'Garrett Colton', date: 'October 21, 2021', amount: 2000, instrument: '$4M SAFE (post money)', photo: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png' },
        { name: 'Verenice Chavoya', date: 'September 24, 2021', amount: 1500, instrument: '$4M SAFE (post money)', photo: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png' },
        { name: 'John Murphy', date: 'June 27, 2021', amount: 3000, instrument: '$2M SAFE (post money)', photo: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png' },
        { name: 'Henry Bosco', date: 'June 23, 2021', amount: 4000, instrument: '$2M SAFE (post money)', photo: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png' },
        { name: 'Hunter Simensen', date: 'June 11, 2021', amount: 4000, instrument: '$2M SAFE (post money)', photo: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png' },
        { name: 'Zach Greene LLC', date: 'June 3, 2021', amount: 5000, instrument: '$2M SAFE (post money)', photo: 'https://ca.slack-edge.com/TTAGMKMD4-UTA6ETYBH-fa4e5be6b53c-192' },
      ],

      authenticationForm: {
        first_name: null,
        last_name: null,
        fund: null,
        password: null
      }
    }
  },
  mounted() {
    const cache = localStorage.getItem("investor_portal_form")
    if (!cache) return

    const parsedCache = JSON.parse(cache);
    const now = new Date();

    if (now.getTime() < parsedCache.expires) {
      this.authenticationForm = parsedCache.value;
      this.state.authenticated = true;
    }
  },
  methods: {
    goTo(link) {
      window.scrollTo(0, 0);
      this.$router.push(link);
    },

    scheduleCallPressed() {
      window.open('https://calendly.com/bash-jones/30min', '_blank');
    },

    contactTeamPressed() {
      if (this.investmentForm.amount == null) return;
      window.open(`mailto:bash@joinhomeplan.com?subject=Homeplan Investment`);
    },

    authenticationFormSubmit() {
      const form = this.authenticationForm;

      axios.post(`${window.api}/users/get_investor_page`, form)
        .then(response => {
          if (response.status == 200) {
            this.state.authenticated = true;
            window.scrollTo(0, 0);

            var currentDate = new Date();
            var futureDate = new Date(currentDate.getTime() + (60 * 60000));

            localStorage.setItem("investor_portal_form", JSON.stringify({
              value: this.authenticationForm,
              expires: futureDate.getTime()
            }))
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$toast.error(error.response.data);
          }
        });
    },

    viewFilePressed(file) {
      const form = this.authenticationForm;
      form.file = file;

      this.$toast.info("Fetching file. Please wait...");

      axios.post(`${window.api}/users/view_investor_file`, form)
        .then(({ data }) => {
          window.open(data, '_blank')
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$toast.error(error.response.data);
            this.state.authenticated = false;
          }
        });
    }
  }
}
</script>


<style scoped>
header {
  widows: 100%;
  position: fixed;
  z-index: 1000;
}
</style>